@import "../styles/colors.module.scss";

.login-container {
  border: 1px solid $light-grey;
  background-color: $white;
  display: block;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  border-radius: 1rem;
  padding: 3rem 3rem 2rem 3rem;
  min-height: 470px;
}
.login-text {
  font-size: 2rem;
  font-weight: 600;
}
.login-text-small {
  font-size: 1rem;
  color: $grey;
}
