#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
  max-width: 1290px;
}
