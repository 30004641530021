@import "../styles/colors.module.scss";

.unit-name {
  color: $blue;
  font-weight: 700;
  margin-right: 0.5rem;
}

.unit-name-dot {
  color: $red;
}
