@import "../../styles/colors.module.scss";

.link-button.secondary {
  color: $grey;
}

.link-button.danger {
  color: $red;
}

.link-button.selected,
.link-button.selected:focus {
  font-weight: bold;
  text-decoration: underline;
  color: $blue;
}

.link-button {
  border: none !important;
  padding: 0;
  background-color: transparent;
  color: $blue;
  text-align: left;
}

.link-button:hover,
.link-button:focus {
  color: $navy;
  outline: none;
}
