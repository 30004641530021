@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Poppins-Bold.ttf");
}

$font-family-sans-serif: "Poppins", san-serif;
$font-family-base: $font-family-sans-serif;
