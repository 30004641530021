@import "../styles/colors.module.scss";

/* Needed otherwise the info tooltip for Email wraps onto the next line of the `inline-block` label 
   element. If you find a better way to accomplish the same thing replace this with it! */
label[for="email"] {
  min-width: 100px;
}

.authcard h6 {
  color: $black;
}
