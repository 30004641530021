.tags-input .dropdown-menu {
  max-height: 200px !important;
}

.tags-input {
  margin-bottom: 200px;
}

.color {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: -1px;
  margin-right: 2px;
  vertical-align: middle;
  border-radius: 7px;
}
