@import "./colors.module.scss";
@import "./typography.scss";
@import "react-bootstrap-typeahead/css/Typeahead.css";

// Theme colors
$theme-colors: (
  primary: $blue,
  secondary: $grey,
  success: $green,
  info: $sky-blue,
  warning: $yellow,
  danger: $red,
);

// Links
$link-decoration: none;
$link-hover-decoration: none;

@import "node_modules/bootstrap/scss/bootstrap.scss";

body {
  background-color: $off-white;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.jumbotron {
  background-color: transparent;
}

.card-footer {
  background-color: $white;
}

.inline-icon {
  display: inline;
  max-height: 0.75em;
  max-width: 0.75em;
  margin: 0.05em 0.25em 0.25em 0.15em;
}

.inline-icon-big {
  display: inline;
  max-height: 1.5em;
  max-width: 1.5em;
  margin: 0.05em 0.25em 0.25em 0.15em;
}

.dropdown-button {
  min-height: 3rem;
  color: $blue !important;
  // take the bare minimum from .dropdown-item css in bootstrap
  width: 100%;
  background-color: transparent;
  border: 0;
  padding-left: 1.5rem;
  text-align: left;
}

.dropdown-button:hover {
  color: $navy !important;
}

.blue-highlight {
  background: linear-gradient(
    180deg,
    transparent 45%,
    $light-blue 45% 80%,
    transparent 80% 100%
  );
}

.red-highlight {
  background: linear-gradient(
    180deg,
    transparent 45%,
    $light-red 45% 80%,
    transparent 80% 100%
  );
}

.semibold {
  font-weight: 600;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}

a:hover,
a:focus {
  color: $navy !important;
}

.toast {
  z-index: 99999; // Make sure toasts appear on top even when modals are open
  position: absolute;
  top: 1rem;
  right: 1rem;
}

dt {
  font-weight: normal;
  font-size: 0.9rem;
  color: $grey;
  font-weight: 600;
}

dd {
  color: $black;
}

.small {
  font-size: 0.8em;
}

// change all paths to blue stroke when any part of the svg is hovered
svg.hover-blue:hover path {
  stroke: $blue;
}
