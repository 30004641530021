.education-carousel {
  padding-bottom: 40px; // claim space for bottom selectors
  min-height: 240px; // try to reduce page changes as carousel scrolls

  img {
    width: 100%;
  }

  .carousel-indicators li {
    background-color: #000;
  }

  // use the black icons from bootstrap v2.2
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  }

  // claim space on left/right for arrow controls
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }

  .carousel-inner {
    width: 80%;
    margin: 0 auto;
  }
}
