#footer-logo {
  max-height: 1rem;
}

#footer-brand {
  padding-top: 0;
}

#footer {
  font-size: 0.8rem;
}

.intercom-margin {
  margin-right: 5rem;
}
