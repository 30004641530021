// Because of CRA config, if we want to import scss variables into javascript,
// we need to name the file {filename}.module.scss.
// https://github.com/facebook/create-react-app/issues/10047
$white: #ffffff;
$off-white: #f9f8f6;
$light-grey: #e1e1e1;
$grey: #626262;
$black: #000000;

$blue: #0761f5;
$med-blue: #9dc1f8;
$light-blue: #d4e4fc;

$red: #ec3d35;
$light-red: #f48b86;

$yellow: #ffde59;

$green: #69a682;
$dark-green: #436958;
$light-green: #a9cfbe;

$orange: #e87034;
$sky-blue: #89d8ff;
$navy: #001942;

:export {
  white: $white;
  offWhite: $off-white;
  lightGrey: $light-grey;
  grey: $grey;
  black: $black;

  blue: $blue;
  medBlue: $med-blue;
  lightBlue: $light-blue;

  red: $red;
  lightRed: $light-red;

  yellow: $yellow;

  green: $green;
  darkGreen: $dark-green;
  lightGreen: $light-green;

  orange: $orange;
  skyBlue: $sky-blue;
  navy: $navy;
}
